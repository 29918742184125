import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaFacebook } from 'react-icons/fa'
import { ReactNode } from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic'

const Logo = (props: any) => {
  return (
    <Image src="/images/gaudia-logo.png" width={150} height={150 * 0.322061192} loading="eager" />
  )
}

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const CopyrightText = () => <Text>All rights reserved © Gaudia {new Date().getFullYear()}</Text>

const Copyright = dynamic(async () => CopyrightText, { ssr: false })

export function Footer() {
  return (
    <Box>
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
      >
        <Container as={Stack} maxW={'6xl'} py={4} spacing={4} justify={'center'} align={'center'}>
          <Logo />
          {/*<Stack direction={'row'} spacing={6}>
          <Link href={'#'}>Home</Link>
          <Link href={'#'}>About</Link>
          <Link href={'#'}>Blog</Link>
          <Link href={'#'}>Contact</Link>
        </Stack>*/}
        </Container>

        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
        >
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}
          >
            <Copyright />
            <Stack direction={'row'} spacing={6}>
              <SocialButton
                label={'Facebook'}
                href={
                  'https://www.facebook.com/Gaudia-Servizi-di-Semplificazione-Digitale-102246635471596'
                }
              >
                <FaFacebook />
              </SocialButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}
