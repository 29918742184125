import NextImage from 'next/image'
import React from 'react'
import { Container, Heading, Text, Box } from '@chakra-ui/react'
import { Layout } from '../components/Layout'
import { FeatureBlock } from '../components/FeatureBlock'
import { Hero } from '../components/Hero'
import { HighlightedText } from '../components/HighlightedText'
import { ContactForm } from '../components/ContactForm/ContactForm'
import misurazioneTemperatura from '../public/images/misurazione-temperatura.png'
import valutazionePerformance from '../public/images/valutazione-performance.png'
import eliminaCodeEvoluto from '../public/images/elimina-code-evoluto.png'
import pagamentiElettroniciIntegrati from '../public/images/pagamenti-elettronici-integrati.png'
import riduzioneListeAttesa from '../public/images/riduzione-liste-attesa.png'
import prenotazioneDaCasa from '../public/images/prenotazione-da-casa.png'
import contrattoFlessibile from '../public/images/contratto-flessibile.png'

export default function Home() {
  return (
    <Layout>
      <Hero />
      <FeatureBlock
        title={
          <>
            Misurazione della temperatura con termoscanner{' '}
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              autonomo
            </HighlightedText>
          </>
        }
        description={
          <>
            Il termoscanner integrato rileva la temperatura e la presenza della mascherina negli
            utenti. Se la temperatura è sopra soglia o la mascherina non è indossata, Gaudia non
            permette l’ingresso.
          </>
        }
        image={
          <NextImage
            src={misurazioneTemperatura}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />
      <FeatureBlock
        title={
          <>
            Valutazione delle{' '}
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              performance
            </HighlightedText>{' '}
            fornite alla Struttura Sanitaria
          </>
        }
        alignment="right"
        description={
          <>
            La piattaforma di Gaudia consente la produzione di statistiche quantitative utili alla
            valutazione delle performance di struttura e al processo decisionale del manager.
          </>
        }
        image={
          <NextImage
            src={valutazionePerformance}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />
      <FeatureBlock
        title={
          <>
            Elimina code{' '}
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              evoluto
            </HighlightedText>
          </>
        }
        description={
          <>
            L’algoritmo di Gaudia riduce di oltre il 60% i tempi medi di attesa dell’utente,
            migliorando così l’immagine aziendale. I tuoi dipendenti vivranno un contesto armonico e
            valorizzante.
          </>
        }
        image={
          <NextImage
            src={eliminaCodeEvoluto}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />
      <FeatureBlock
        title={
          <>
            Pagamenti elettronici{' '}
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              integrati
            </HighlightedText>
          </>
        }
        alignment="right"
        description={
          <>
            Gaudia permette all’utente di pagare direttamente i servizi di cui usufruisce. Un
            terminale contactless sicuro e personalizzabile guiderà l’utente verso il pagamento.
          </>
        }
        image={
          <NextImage
            src={pagamentiElettroniciIntegrati}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />
      <FeatureBlock
        title={
          <>
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              Riduzione
            </HighlightedText>{' '}
            delle liste d&apos;attesa
          </>
        }
        description={
          <>
            Tramite un semplice sistema di richiamata dell’utente, Gaudia riprogramma la
            prenotazione e consente di smaltire notevolmente le liste d’attesa.
          </>
        }
        image={
          <NextImage
            src={riduzioneListeAttesa}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />
      <FeatureBlock
        title={
          <>
            Prenotazione da{' '}
            <HighlightedText color={'secondary.400'} highlightColor="primary.100">
              casa
            </HighlightedText>
          </>
        }
        alignment="right"
        description={
          <>
            Con semplicità Gaudia offre un’interfaccia online per consentire all’utente di prenotare
            direttamente da casa. Risparmia il tempo sprecato in telefonate e appuntamenti
            dimenticati, è tutto molto più semplice, fidati.
          </>
        }
        image={
          <NextImage
            src={prenotazioneDaCasa}
            layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
        }
      />

      <Box
        bg="#0a2540"
        position="relative"
        py={20}
        /*_before={{
          content: '""',
          position: 'absolute',
          top: '-14vh',
          bottom: '-14vh',
          left: '-1%',
          right: '-1%',
          transform: 'rotate(-5deg) skew(-5deg)',
          background: '#0a2540',
          zIndex: -1,
        }}*/
      >
        <Container maxW="container.lg" textAlign="center">
          <Box mb={4}>
            <Heading color={'primary.500'} fontSize={['3xl', '4xl', '5xl']} mb={3}>
              Un motivo in più per sceglierci? <br />
              <Text color="secondary.400">Il nostro contratto è flessibile!</Text>
            </Heading>
            <Text color={'gray.100'} fontSize={['lg', 'xl']} px={10}>
              Non è importante quanto sia grande la tua azienda e quale sia il tuo business.
              Vogliamo che Gaudia sia un investimento e non un costo, per questo puntiamo su un
              prezzo variabile in base al reale utilizzo di Gaudia.
            </Text>
          </Box>
          <NextImage
            src={contrattoFlessibile}
            // layout="responsive"
            placeholder="blur"
            lazyBoundary="400px"
          />
          {/*PAGHI SOLAMENTE CIÒ CHE CONSUMI*/}
        </Container>
      </Box>

      <ContactForm />
    </Layout>
  )
}
