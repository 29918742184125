import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
  Link,
  Box,
  Alert,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
  AlertIcon,
  Grid,
  GridItem,
  Divider,
} from '@chakra-ui/react'
import { FaPhone, FaWhatsapp } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'
import dynamic from 'next/dynamic'
import { NotificationIcon } from './NotificationIcon'

const Obfuscate = dynamic<{ tel?: string; href?: string; email?: string }>(
  async () => import('react-obfuscate').then((r) => r.default),
  {
    ssr: false,
  },
)

export const ContactForm = () => {
  const submissionEndpoint = process.env.NEXT_PUBLIC_FORM_SUBMISSION_ENDPOINT
  const ref = useRef<HTMLDivElement>()

  const [formData, updateFormField] = useReducer(
    (data: Record<string, any>, args: [string, any]) => {
      return {
        ...data,
        [args[0]]: [args[1]],
      }
    },
    { fullName: '', email: '', message: '' },
  )

  const isFormValid = useMemo(() => Object.values(formData).every((v) => v), [formData])

  const handleFormFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      updateFormField([e.target.name, e.target.value]),
    [],
  )

  const [formStatus, setFormStatus] = useState<'idle' | 'submitting' | 'submitted' | 'error'>(
    'idle',
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormStatus('submitting')
    console.log('submissionEndpoint', submissionEndpoint)

    fetch(submissionEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        ...formData,
      }),
    })
      .then((res) => res.json())
      .then(() => setFormStatus('submitted'))
      .catch((error) => setFormStatus('error'))
  }

  return (
    <Flex
      id="contactForm"
      ref={ref}
      align={'center'}
      justify={'center'}
      py={[5, 5, 20]}
      bg={[null, null, useColorModeValue('gray.50', 'gray.800')]}
    >
      <Stack
        boxShadow={['none', 'none', '2xl']}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={10}
        spacing={8}
        align={'center'}
        maxWidth="800"
      >
        <Icon as={NotificationIcon} w={24} h={24} />
        <Stack align={'center'} spacing={2}>
          <Heading
            textTransform={'uppercase'}
            fontSize={'3xl'}
            color={useColorModeValue('gray.800', 'gray.200')}
          >
            Contattaci
          </Heading>

          {formStatus !== 'submitted' && (
            <>
              <Text fontSize={'lg'} color={'gray.500'}>
                Se vuoi saperne di piú sui servizi che Gaudia puó offrirti, usa i seguenti contatti:
              </Text>
              <Flex
                flexDirection={['column', 'column', 'row']}
                justify="space-between"
                width="100%"
              >
                <Box mb={[5, 5, 0]}>
                  <Button
                    display="flex"
                    isFullWidth
                    leftIcon={<FaPhone />}
                    size="lg"
                    p={[15, 15, 25]}
                    height="auto"
                  >
                    <Obfuscate tel="+39 338 310 0400" />
                  </Button>
                </Box>
                <Box mb={[5, 5, 0]}>
                  <Button
                    display="flex"
                    isFullWidth
                    leftIcon={<AiOutlineMail />}
                    size="lg"
                    p={[15, 15, 25]}
                    height="auto"
                  >
                    <Obfuscate email="info@gaudiaweb.com" />
                  </Button>
                </Box>
                <Box>
                  <Button
                    display="flex"
                    isFullWidth
                    leftIcon={<FaWhatsapp />}
                    size="lg"
                    p={[15, 15, 25]}
                    height="auto"
                  >
                    <Obfuscate href="https://wa.me/393383100400">WhatsApp</Obfuscate>
                  </Button>
                </Box>
              </Flex>
            </>
          )}
        </Stack>
        <Stack spacing={4} direction={{ base: 'column', md: 'row' }} w={'full'}>
          {formStatus === 'submitted' && (
            <Box flex={1}>
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Richiesta inviata!
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  Grazie. Verrai ricontattato a breve da un nostro consulente
                </AlertDescription>
              </Alert>
            </Box>
          )}
          {formStatus !== 'submitted' && (
            <Box w="100%">
              <Divider my={5} />
              <Box>
                <Text as="div" fontSize={'lg'} color={'gray.500'} textAlign="center" mb={5}>
                  oppure lascia un messaggio utilizzando il form a seguire
                </Text>
              </Box>
              <form style={{ flex: 1 }} onSubmit={handleSubmit}>
                <FormControl id="fullName" mb={5}>
                  <FormLabel>Nome</FormLabel>
                  <Input name="fullName" onChange={handleFormFieldChange} />
                </FormControl>

                <FormControl id="email" mb={5}>
                  <FormLabel>Email</FormLabel>
                  <Input name="email" onChange={handleFormFieldChange} />
                </FormControl>

                <FormControl id="message" mb={5}>
                  <FormLabel>Messaggio</FormLabel>
                  <Textarea
                    name="message"
                    rows={5}
                    resize="vertical"
                    onChange={handleFormFieldChange}
                  />
                </FormControl>

                <Button
                  isFullWidth
                  size="lg"
                  flex={1}
                  mt={4}
                  colorScheme="teal"
                  type="submit"
                  disabled={!isFormValid || formStatus === 'submitting'}
                >
                  {formStatus === 'submitting' ? 'Invio in corso..' : 'Invia'}
                </Button>
              </form>
            </Box>
          )}
        </Stack>
      </Stack>
    </Flex>
  )
}
