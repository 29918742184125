import React from 'react'
import { Flex, Box, ChakraProps } from '@chakra-ui/react'
import { TopBar } from './TopBar'
import { Footer } from './Footer'

export interface LayoutProps extends ChakraProps {
  topBar?: JSX.Element
  footer?: JSX.Element
}

export const Layout: React.FC<LayoutProps> = ({ children, topBar, footer, ...props }) => {
  return (
    <Flex direction="column" width="100vw" minHeight="100vh" overflowX="hidden" {...props}>
      {topBar}

      <Box flex={1}>{children}</Box>

      {footer}
    </Flex>
  )
}

Layout.defaultProps = {
  topBar: <TopBar />,
  footer: <Footer />,
}
