import React from 'react'
import {
  chakra,
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  Box,
  useColorModeValue,
} from '@chakra-ui/react'
import NextImage from 'next/image'

interface Props {
  title: React.ReactNode
  description: React.ReactNode
  image: React.ReactNode
  alignment?: 'left' | 'right'
}

export const FeatureBlock: React.FC<Props> = ({ title, description, image, alignment }) => {
  return (
    <Container maxW="container.xl" p={[4, 12]}>
      <Flex direction={['column', 'column', 'row']} alignItems="center">
        <Stack
          spacing={4}
          width={['100%', '100%', '33%']}
          order={alignment === 'right' && [1, 1, 2]}
          textAlign={['center', 'initial']}
        >
          {/*<Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}
          >
            Our Story
          </Text>*/}
          <Heading color={'primary.500'} fontSize={['2xl', '2xl', '4xl']}>
            {title}
          </Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            {description}
          </Text>
        </Stack>

        <Box
          position="relative"
          width={['100%', '100%', '66%']}
          {...(alignment === 'right' ? { order: [1, 1, 1], pr: 3 } : { pl: 3 })}
        >
          {image}
        </Box>
      </Flex>
    </Container>
  )
}

FeatureBlock.defaultProps = { alignment: 'left' }
