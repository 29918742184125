import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
import * as CSS from 'csstype'

interface HighlightedTextProps extends TextProps {
  highlightColor: Token<CSS.Property.Color, 'colors'>
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  children,
  highlightColor,
  ...props
}) => (
  <Text
    {...props}
    as={'span'}
    position="relative"
    _after={{
      content: "''",
      width: 'full',
      height: '30%',
      position: 'absolute',
      bottom: 1,
      left: 0,
      bg: highlightColor,
      zIndex: -1,
    }}
  >
    {children}
  </Text>
)
